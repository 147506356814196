import {useEffect, useMemo, useState} from "react";
import {Layer, Source} from "react-map-gl/maplibre";

export function IconLayer({dataInput, id, color, minZoomForLabels}) {

  const [data, setData] = useState(dataInput || []);
  useEffect(() => {
    if (dataInput) return;

    fetch(`/${id}.json`)
      .then(res => res.json())
      .then(setData);
  }, [dataInput, id]);

  useEffect(() => {
    console.log(`data for ${id}`, data)
  }, []);

  const points = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: data.map(poi => {
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [poi.longitude || poi.lon, poi.latitude || poi.lat]
          },
          properties: {label: poi.name}
        }
      })
    };
  }, [data]);

  const labelledLargeIcons = {
    id: `${id}-labelledLargeIcons`,
    // beforeId: 'Country labels',
    type: 'symbol',
    minzoom: minZoomForLabels !== undefined ? minZoomForLabels : 10,
    layout: {
      'icon-image': 'circle',
      'icon-size': .6,
      'text-field': ['get', 'label'],
      'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
      'text-radial-offset': 0.8,
      'text-justify': 'auto',
      'text-size': [
        'interpolate',
        ['exponential', 0.5],
        ['zoom'],
        10,
        9,
        17,
        14,
      ]
    },
    paint: {
      'icon-color': color,
      'text-color': color,
      'icon-halo-width': 2,
      'text-halo-width': 2,
      'icon-halo-color': 'white',
      'text-halo-color': 'white',
      'icon-halo-blur': 0,
      'text-halo-blur': 0,
    },
  };

  const unlabelledSmallIcons = {
    id: `${id}-unlabelledSmallIcons`,
    background: true,
    type: 'symbol',
    layout: {
      'icon-image': 'circle',
      'icon-allow-overlap': true,
      'icon-size': .4,
    },
    paint: {
      'icon-color': color,
      'icon-halo-width': 1,
      'icon-halo-color': 'white',
      'icon-halo-blur': 0,
    },
  };

  return <Source id={id} type="geojson" data={points}>
    <Layer {...unlabelledSmallIcons} beforeId={'Other POI'}/>
    <Layer {...labelledLargeIcons} beforeId={'Other POI'}/>
  </Source>;
}
