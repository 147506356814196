import {useState} from "react";
import {Flag} from "./Flag";
import {CountryNetTable} from "./CountryNetTable";
import {CountryCharts} from "./CountryCharts";

export function Country({selectedCountry, unsetSelectedCountry, selectNet}) {
  const [currentTab, setCurrentTab] = useState('asTable');

  return <dialog open>
    <article style={{display: 'flex', flexDirection: 'column', paddingBottom: 0, maxWidth: 'none', flexGrow: 1}}>
      <header style={{whiteSpace: 'nowrap', marginBottom: '0', fontSize: 'initial', paddingTop: 0, paddingBottom: 0}}>
        <nav>
          <ul>
            <li>
              <strong>
                <Flag iso={selectedCountry.country_iso} alt={selectedCountry.country_name} style={{height: '1rem', marginRight: '0.7rem'}}/>
                {selectedCountry.country_name}
              </strong>
            </li>
          </ul>
          <ul>
            <li><a style={{fontWeight: currentTab === 'asTable' ? 'bold' : 'initial'}} href="#" onClick={() => setCurrentTab('asTable')}>AS Table</a></li>
            <li><a style={{fontWeight: currentTab === 'charts' ? 'bold' : 'initial'}} href="#" onClick={() => setCurrentTab('charts')}>Charts</a></li>
            <li><a href="#close" onClick={() => unsetSelectedCountry()} aria-label="Close" className="close"></a></li>
          </ul>
        </nav>


      </header>
      <div style={{overflow: 'scroll'}}>
        {currentTab === "asTable" && <CountryNetTable selectedCountry={selectedCountry} selectNet={selectNet}/>}
        {currentTab === "charts" && <CountryCharts selectedCountry={selectedCountry}/>}
      </div>
    </article>
  </dialog>;
}
