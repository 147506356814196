import {IpsInRegCountryScatterChart} from "../chart/IpsInRegCountryScatterChart";
import {IpsInRegCountryBarChart} from "../chart/IpsInRegCountryBarChart";
import {IpsInNetBarChart} from "../chart/IpsInNetBarChart";
import {IpsInNetPerNetTypeCdfChart} from "../chart/IpsInNetPerNetTypeCdfChart";

export function Charts({selectedCountry, setShowCharts}) {

  return <dialog open>
    <article style={{display: 'flex', flexDirection: 'column', paddingBottom: 0, maxWidth: 'none', flexGrow: 1}}>
      <header style={{whiteSpace: 'nowrap', marginBottom: '0', fontSize: 'initial'}}>
        <a href="#close" onClick={() => setShowCharts(false)} aria-label="Close" className="close"></a>
        Charts
      </header>
      <div style={{marginTop: '1rem'}}>
        <IpsInNetBarChart/>
        <IpsInNetPerNetTypeCdfChart/>
        <IpsInRegCountryBarChart/>
        <IpsInRegCountryScatterChart/>
      </div>
    </article>
  </dialog>;
}