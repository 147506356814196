import {useContext} from "react";
import {DataContext} from "../map/DataContext";
import {Chart} from "./Chart";

export function IpsInRegCountryScatterChart() {
  const {nets} = useContext(DataContext);

  const data = Object.values(nets)
    .filter(net => Number.isInteger(net.num_of_addresses_in_registered_country))
    .filter(net => net.num_of_addresses > 0)
    .map(net => {
      const numOfAddresses = net.num_of_addresses;
      const shareInRegCountry = 100 * net.num_of_addresses_in_registered_country / numOfAddresses
      return [numOfAddresses, shareInRegCountry];
    });

  const option = {
    xAxis: {
      type: 'log',
      logBase: 256,
    },
    yAxis: {},
    tooltip: {
      show: true,
    },
    series: [
      {
        type: 'scatter',
        symbolSize: 5,
        data,
        itemStyle: {
          opacity: 0.15,
        },
      },
    ],
  };

  return <Chart
    title={'Share of IP addresses located in registration country by total number of IP addresses'}
    option={option}
  />;
}