import ReactEcharts from "echarts-for-react";

export function Chart({title, option}) {
  return <>
    <strong>{title}</strong>
    <ReactEcharts
      option={option}
      style={{width: '100%', height: '60vmin'}}
    />
  </>;
}