import {useMap} from "react-map-gl/maplibre";
import {SearchSuggestions} from "./SearchSuggestions";

export function Search({selectNet, setSelectedCountry, searchFocus, setSearchFocus, searchValue, setSearchValue, setShowCharts}) {

  const map = useMap();
  const showSuggestions = searchFocus && searchValue !== '';

  const selectCountry = country => {
    fetch(`/country/${country.country_iso}.json`)
      .then(res => res.json())
      .then(setSelectedCountry);
  };

  return <>
    <div className={'container-fluid'} style={{position: 'absolute', padding: '1rem'}}>
      <div style={{display: 'flex', flexDirection: 'row', gap: '1rem'}}>
        <input
          type={'search'}
          value={searchValue}
          onChange={event => setSearchValue(event.target.value)}
          onFocus={() => setSearchFocus(true)}
          // onBlur={() => window.setTimeout(() => setSearchFocus(false), 100)}
          style={{backgroundColor: '#ffffff90', backdropFilter: 'blur(5px)', flexGrow: 1}}
          placeholder={'Search for an ASN, AS name or country...'}
        />
        <button
          onClick={()=>setShowCharts(true)}
          style={{
            backgroundColor: '#ffffff90',
            color: 'var(--form-element-color)',
            borderColor: 'var(--form-element-border-color)',
            backdropFilter: 'blur(5px)',
            flexGrow: 0,
            width: 'auto',
          }}
        >
          Charts
        </button>
      </div>

      {showSuggestions && <SearchSuggestions
        searchValue={searchValue}
        selectNet={selectNet}
        selectCountry={selectCountry}
      />}
    </div>
  </>;
}
