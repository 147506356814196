import {useContext} from "react";
import {DataContext} from "../map/DataContext";
import {Chart} from "./Chart";

export function IpsInRegCountryBarChart() {
  const {nets} = useContext(DataContext);

  const bucketsStep = 20;

  const buckets = {};
  for (let minShare = 0; minShare <= 100; minShare += bucketsStep) {
    const maxShare = minShare + bucketsStep;
    buckets[minShare] = {
      minShare,
      maxShare,
      label: minShare < 100 ? `< ${maxShare}%` : '100%',
      numOfNets: 0,
    }
  }

  Object.values(nets)
    .filter(net => Number.isInteger(net.num_of_addresses_in_registered_country))
    .filter(net => net.num_of_addresses > 0)
    .forEach(net => {
      const shareInRegCountry = 100 * net.num_of_addresses_in_registered_country / net.num_of_addresses
      const bucket = Math.floor(shareInRegCountry / bucketsStep) * bucketsStep;
      buckets[bucket].numOfNets++;
    });

  const option = {
    xAxis: {
      data: Object.values(buckets).map(bucket => bucket.label),
    },
    yAxis: {
      type: 'log',
    },
    tooltip: {
      show: true,
    },
    series: [
      {
        type: 'bar',
        data: Object.values(buckets).map(bucket => bucket.numOfNets),
      },
    ],
  };

  return <Chart
    title={'Share of IP addresses located in registration country'}
    option={option}
  />;
}