import {Flag} from "./Flag";
import {useContext} from "react";
import {DataContext} from "./DataContext";

export function NetCountries({net, ...flagProps}) {
  const {countries} = useContext(DataContext);

  return net.mm_top_country && <>
    <Flag
      iso={net.mm_top_country}
      alt={countries[net.mm_top_country].country_name}
      {...flagProps}
    />
    {net.num_of_countries > 1 && <>+{net.num_of_countries - 1}</>}
  </>;
}