import {useContext} from "react";
import {DataContext} from "./DataContext";
import {Flag} from "./Flag";
import {NetCountries} from "./NetCountries";

export function SearchSuggestions({searchValue, selectNet, selectCountry}) {

  const {nets, countries} = useContext(DataContext);

  const includesCaseInsensitive = (haystack, needle) => haystack?.toLowerCase().includes(needle?.toLowerCase());

  return <div style={{display: 'flex', justifyContent: 'space-between'}}>
    <div style={{display: 'inline-flex', flexDirection: 'column', marginRight: '1rem'}}>
      {
        Object.values(nets)
          .filter(net => (net.name && includesCaseInsensitive(net.name, searchValue)) || net.asn.toString().includes(searchValue))
          .sort((a, b) => b.num_of_addresses - a.num_of_addresses)
          .sort((a, b) => (`${b.asn}` === searchValue) - (`${a.asn}` === searchValue))
          .slice(0, 50)
          .map(net => {
              const subText = [];

              if (net.mm_top_country) {
                subText.push(<NetCountries net={net} style={{height: '.5rem'}}/>);
              }

              subText.push(<tt>AS{net.asn}</tt>);
              subText.push(<>{net.num_of_facilities} {net.num_of_facilities === 1 ? 'facility' : 'facilities'}</>);
              subText.push(<>{net.num_of_addresses?.toLocaleString() || '?'} {net.num_of_addresses === 1 ? 'IP address' : 'IP addresses'}</>);

              return <button key={net.asn} onClick={() => selectNet(net)} style={{
                cursor: 'pointer',
                lineHeight: 1,
                textAlign: 'left',
                backdropFilter: 'blur(5px)',
                // opacity: selectedNet.facilities.length > 0 ? 1 : 0.5,
                margin: '0.1rem',
                padding: '0.5rem 1rem',
                backgroundColor: '#ffffff80',
                border: 'none',
                color: '#333',
              }}>
                {net.name || `AS${net.asn}`}<br/>
                <span style={{fontSize: '70%'}}>
                {subText.reduce((result, item) => <>{result}{' | '}{item}</>)}
              </span>
              </button>
            }
          )
      }
    </div>
    <div style={{display: 'inline-flex', flexDirection: 'column'}}>
      {
        Object.values(countries)
          .filter(country =>
            (searchValue?.length >= 2 && includesCaseInsensitive(country.country_name, searchValue))
            || country.country_iso === searchValue?.toUpperCase()
          )
          .sort((a, b) => b.num_of_addresses - a.num_of_addresses)
          .sort((a, b) => (`${b.asn}` === searchValue) - (`${a.asn}` === searchValue))
          .slice(0, 5)
          .map(country =>
            <button key={country.country_iso} onClick={() => selectCountry(country)} style={{
              cursor: 'pointer',
              lineHeight: 1,
              textAlign: 'center',
              backdropFilter: 'blur(5px)',
              // opacity: selectedNet.facilities.length > 0 ? 1 : 0.5,
              margin: '0.1rem',
              padding: '0.5rem 1rem',
              backgroundColor: '#ffffff80',
              border: 'none',
              color: '#333',
            }}>
              <Flag
                iso={country.country_iso}
                style={{width: '8rem', display: 'block', margin: '.5rem'}}
                alt={country.country_name}
              />
              {country.country_name}<br/>
              <span style={{fontSize: '70%'}}>
                {country.nets.length} AS
              </span>
            </button>
          )
      }
    </div>
  </div>;
}