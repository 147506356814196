import {Flag} from "./Flag";
import {NetCountries} from "./NetCountries";
import {useContext} from "react";
import {DataContext} from "./DataContext";
import {CountryNetTableCsvExport} from "./CountryNetTableCsvExport";

CountryNetTableCsvExport.propTypes = {};

export function CountryNetTable({selectedCountry, selectNet}) {
  const {nets, countries} = useContext(DataContext);

  return <>
    <CountryNetTableCsvExport selectedCountry={selectedCountry}/>
    <table style={{marginTop: '1rem', width:'auto'}} role={'grid'}>
      <thead>
      <tr>
        <th style={{textAlign: 'right'}}>ASN</th>
        <th>AS name</th>
        <th style={{textAlign: 'right'}}>IPs</th>
        <th style={{textAlign: 'right'}}>IPs in Reg. Country</th>
        <th>Reg. Country</th>
        <th>Present in Country</th>
      </tr>
      </thead>
      <tbody>
      {selectedCountry.nets
        .map(asn => nets[asn])
        .map(net => {
            return <tr key={net.asn}>
              <td style={{textAlign: 'right'}}>
                <a onClick={() => selectNet(net)}>AS{net.asn}</a>
              </td>
              <td>{net.name || <span style={{opacity: 0.4}}>Unknown</span>}</td>
              <td style={{textAlign: 'right'}}>
                {net.num_of_addresses?.toLocaleString() || '?'}
              </td>
              <td style={{textAlign: 'right'}}>
                {
                  Number.isInteger(net.num_of_addresses_in_registered_country)
                    ? `${(100 * net.num_of_addresses_in_registered_country / net.num_of_addresses).toFixed(1)} %`
                    : '?'
                }
              </td>
              <td>
                <Flag
                  iso={net.registered_country}
                  alt={countries[net.registered_country]?.country_name}
                  style={{height: '.8rem'}}
                />
              </td>
              <td><NetCountries net={net} style={{height: '.8rem'}}/></td>
            </tr>;
          }
        )}
      </tbody>
    </table>
  </>;
}