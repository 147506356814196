import {useContext} from "react";
import {DataContext} from "../map/DataContext";
import {Chart} from "./Chart";

export function IpsInNetBarChart({selectedCountry}) {
  const {nets} = useContext(DataContext);

  const bucketsExponentStep = 2;

  const buckets = {};
  for (let minIpsExponent = 0; minIpsExponent <= 32; minIpsExponent += bucketsExponentStep) {
    const maxIpsExponent = minIpsExponent + bucketsExponentStep;
    buckets[minIpsExponent] = {
      minIps: Math.pow(2, minIpsExponent),
      maxIps: Math.pow(2, maxIpsExponent),
      label: minIpsExponent < 32 ? `< 2^${maxIpsExponent} IPs` : 'THE WHOLE INTERNET',
      numOfNets: 0,
    }
  }

  const chartNets = selectedCountry
    ? selectedCountry.nets.map(asn=>nets[asn])
    : Object.values(nets);

  chartNets
    .filter(net => net.num_of_addresses > 0)
    .forEach(net => {
      const bucketExponent = Math.log2(net.num_of_addresses);
      const bucket = Math.floor(bucketExponent / bucketsExponentStep) * bucketsExponentStep;
      buckets[bucket].numOfNets++;
    });

  const option = {
    xAxis: {
      data: Object.values(buckets).map(bucket => bucket.label),
    },
    yAxis: {},
    tooltip: {
      show: true,
    },
    series: [
      {
        type: 'bar',
        data: Object.values(buckets).map(bucket => bucket.numOfNets),
        itemStyle: {
          normal: {
            label: {
              show: true,
              position: 'insideBottom',
            },
          },
        },
      },
    ],
  };

  return <Chart
    title={'Amount of IP addresses in nets'}
    option={option}
  />;
}