import Map from 'react-map-gl/maplibre';
import 'maplibre-gl/dist/maplibre-gl.css';
import '@picocss/pico/css/pico.min.css';
import {useEffect, useState} from "react";
import {DataContext} from "./DataContext"
import {IconLayer} from "./IconLayer";
import MAPTILER_API_KEY from "./MapTilerApiKey.json"
import {Search} from "./Search";
import {LocationLayer} from "./LocationLayer";
import {Country} from "./Country";
import {SelectNet} from "./SelectNet";
import {Charts} from "./Charts";

function App() {
  const [viewState, setViewState] = useState({
    longitude: 8.718848,
    latitude: 50.112273,
    zoom: 13.5
  });

  const [selectedNet, setSelectedNet] = useState(undefined);
  const [selectedCountry, setSelectedCountry] = useState(undefined);
  const [searchValue, setSearchValue] = useState('');
  const [searchFocus, setSearchFocus] = useState(false);
  const [showCharts, setShowCharts] = useState(false);

  const selectNet = selectedNet => {

    setSearchFocus(false);
    setSearchValue(selectedNet.name || `${selectedNet.asn}`);
    setSelectedCountry(null);

    fetch(`/net/${selectedNet.asn}.json`)
      .then(res => res.json())
      .then(setSelectedNet);
  }

  const [nets, setNets] = useState([]);
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    fetch('/nets.json')
      .then(res => res.json())
      .then(setNets);
    fetch('/countries.json')
      .then(res => res.json())
      .then(setCountries);
  }, []);

  return <DataContext.Provider value={{nets, countries}}>
    <Map
      {...viewState}
      onMove={evt => setViewState(evt.viewState)}
      mapStyle={`https://api.maptiler.com/maps/bright-v2/style.json?key=${MAPTILER_API_KEY}`}
    >
      {/*<IconLayer id={'organizations'} color={'#4fad02'}/>*/}
      {/*<IconLayer id={'facilities'} color={'#4262ce'}/>*/}

      <Search
        selectNet={selectNet}
        setSelectedCountry={setSelectedCountry}
        searchFocus={searchFocus}
        setSearchFocus={setSearchFocus}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setShowCharts={setShowCharts}
      />

      <SelectNet
        selectedNet={selectedNet}
      />

      {selectedCountry &&
        <Country
          selectedCountry={selectedCountry}
          unsetSelectedCountry={() => setSelectedCountry(undefined)}
          selectNet={selectNet}
        />
      }

      {showCharts &&
        <Charts
          selectedCountry={selectedCountry}
          setShowCharts={setShowCharts}
        />
      }

      {selectedNet?.facilities &&
        <IconLayer
          dataInput={selectedNet.facilities}
          key={`${selectedNet.asn}.facilities`}
          id={'netFacilities'}
          color={'red'}
          minZoomForLabels={0}
        />
      }

      {selectedNet?.locations &&
        <LocationLayer
          locations={selectedNet.locations}
          maxNumOfAddresses={Math.max(...selectedNet.locations.map(location => location.num_of_addresses))}
          key={`${selectedNet.asn}.locations`}
          id={'netLocations'}
          color={'blue'}
        />
      }
    </Map>
  </DataContext.Provider>;
}

export default App;
