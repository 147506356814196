import {useContext} from "react";
import {DataContext} from "../map/DataContext";
import {Chart} from "./Chart";

export function NetsInCountryBarChart({selectedCountry}) {
  const {nets} = useContext(DataContext);

  const asnsInMaxMind = selectedCountry.nets;

  const asnsRegisteredInCountry = Object.values(nets)
    .filter(net => net.registered_country === selectedCountry.country_iso)
    .map(net => net.asn);

  const intersect = (a, b) => a.filter(el => b.includes(el));
  const minus = (a, b) => a.filter(el => !b.includes(el));

  const data = [
    {
      label: `Nets registered in ${selectedCountry.country_name},\n but no addresses located there`,
      asns: minus(asnsRegisteredInCountry, asnsInMaxMind),
    },
    {
      label: `Nets with addresses located in\n ${selectedCountry.country_name}, but registered elsewhere`,
      asns: minus(asnsInMaxMind, asnsRegisteredInCountry),
    },
    {
      label: `Nets registered and with addresses\n located in ${selectedCountry.country_name}`,
      asns: intersect(asnsRegisteredInCountry, asnsInMaxMind),
    },
  ];

  const option = {
    xAxis: {
      data: data.map(datum => datum.label),
      axisLabel: {
        rotate: 0,
      },
    },
    yAxis: {},
    series: [
      {
        type: 'bar',
        data: data.map(datum => datum.asns.length),
        itemStyle: {
          normal: {
            label: {
              show: true,
              position: 'insideBottom',
            },
          },
        },
      },
    ],
  };

  return <Chart
    title={`Number of nets registered and/or located in ${selectedCountry.country_name}`}
    option={option}
  />;
}