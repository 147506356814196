import {useEffect, useMemo} from "react";
import {Layer, Source} from "react-map-gl/maplibre";

export function LocationLayer({locations, maxNumOfAddresses, id, color}) {

  const points = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: locations.map(poi => {
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [poi.longitude || poi.lon, poi.latitude || poi.lat]
          },
          properties: {numOfAddresses: poi.num_of_addresses}
        }
      })
    };
  }, [locations]);

  const unlabelledSmallIcons = {
    id: `${id}-icons`,
    background: true,
    type: 'symbol',
    layout: {
      'icon-image': 'circle',
      'icon-allow-overlap': true,
      'icon-size': ['interpolate', ['exponential', 2], ['get', 'numOfAddresses'], 0, .5, maxNumOfAddresses, 3],
    },
    paint: {
      'icon-color': color,
    },
  };

  return <Source id={id} type="geojson" data={points}>
    <Layer {...unlabelledSmallIcons} beforeId={'Other POI'} />
  </Source>;
}