import {useContext} from "react";
import {DataContext} from "../map/DataContext";
import {Chart} from "./Chart";

export function IpsInNetPerNetTypeCdfChart({selectedCountry}) {
  const {nets} = useContext(DataContext);

  const chartNets = selectedCountry
    ? selectedCountry.nets.map(asn => nets[asn])
    : Object.values(nets);

  const categorizedNets = chartNets
    .filter(net => Number.isInteger(net.num_of_addresses_in_registered_country))
    .filter(net => net.num_of_addresses > 0)
    .map(net => {
      const shareOfIpsInRegCountry = net.num_of_addresses_in_registered_country / net.num_of_addresses;
      const category = shareOfIpsInRegCountry > .9
        ? '> 90 % in Reg. Country'
        : shareOfIpsInRegCountry < .1
          ? '< 10 % in Reg. Country'
          : '10 .. 90 % in Reg. Country';

      return {
        ...net,
        shareOfIpsInRegCountry,
        category,
      };
    });

  const data = Object.values(Object.groupBy(categorizedNets, net => net.category))
    .map(netGroup => netGroup.sort((a, b) => a.num_of_addresses - b.num_of_addresses))
    .map(netGroup => {
      return {
        category: netGroup[0].category,
        data: netGroup.map((net, index) => [net.num_of_addresses, index / netGroup.length]),
      };
    });

  const option = {
    xAxis: {
      type: 'log',
      logBase: 256,
    },
    yAxis: {},
    tooltip: {
      show: true,
    },
    legend: {},
    series: data.map(categoryData => ({
        type: 'line',
        lineStyle: {
          width: 2,
        },
        symbolSize: 0,
        name: categoryData.category,
        data: categoryData.data,
      })
    ),
  };

  return <Chart
    title={'hm'}
    option={option}
  />;
}