import {LngLatBounds} from "maplibre-gl";
import {useEffect} from "react";
import {useMap} from "react-map-gl/maplibre";

export function SelectNet({selectedNet}) {

  const map = useMap();

  useEffect(() => {
    if (!selectedNet) return;

    const createOrExtendBounds = (bounds, lon, lat) => {
      if (!bounds) {
        const coords = [lon, lat];
        return new LngLatBounds(coords, coords);
      }

      bounds.extend([lon, lat]);
      return bounds;
    }

    let bounds = undefined;

    if (selectedNet.facilities?.length > 0) {
      selectedNet.facilities
        .filter(facility => facility.longitude)
        .forEach(facility => bounds = createOrExtendBounds(bounds, facility.longitude, facility.latitude));
    }
    if (selectedNet.locations?.length > 0) {
      selectedNet.locations
        .filter(location => location.lon)
        .forEach(location => bounds = createOrExtendBounds(bounds, location.lon, location.lat))
    }

    if (bounds) {
      map.current.fitBounds(bounds, {padding: 100, duration: 1000, maxZoom: 16});
    }
  }, [selectedNet]);


  // besides not rendering anything, we need this to be a child component of the Map so we can use the useMap hook
  return null;
}