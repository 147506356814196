import {useContext} from "react";
import {DataContext} from "./DataContext";

export function CountryNetTableCsvExport({selectedCountry}) {
  const {nets} = useContext(DataContext);

  const csvHeader = [
    'ASN',
    'AS name',
    'IPs',
    'IPs in Reg. Country',
    'Reg. country',
    'Top MaxMind country',
    'Number of MaxMind countries',
  ];
  const csvLines = [
    csvHeader.join(';'),
    ...selectedCountry.nets
      .map(asn => nets[asn])
      .map(net => [
        net.asn,
        net.name,
        net.num_of_addresses || '?',
        Number.isInteger(net.num_of_addresses_in_registered_country)
          ? net.num_of_addresses_in_registered_country / net.num_of_addresses
          : '?',
        net.registered_country,
        net.mm_top_country,
        net.num_of_countries,
      ].join(';')),
  ];
  const csvContent = csvLines.join('\n') + '\n';

  return <div style={{textAlign: 'right'}}>
    <a
      href={'data:text/plain;charset=utf-8,' + encodeURIComponent(csvContent)}
      download={`Autonomous Systems in ${selectedCountry.country_iso}.csv`}
      role={'button'}
      style={{margin: '1rem', marginBottom: '1rem'}}
    >
      Download table as CSV
    </a>
  </div>;
}